import React from 'react';

const JoinUsButtons = () =>
{
    return (
        <div className="join-us-wrapper">
            <a href="mailto:info@glaubensfutter.de" className="primary-button">
  info@glaubensfutter.de
            </a>
        </div>
    );
};

export default JoinUsButtons;
