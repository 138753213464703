import React from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';

import { content } from './Participate.markdown';
import JoinUsButtons from '../../atoms/JoinUsButtons';
import PageHeader from '../../atoms/PageHeader';
import globalComponentMountOperations from '../../../infrastructure/utilities/globalComponentMountOperations';
import Head from '../../atoms/Head';
import RouteGenerator from '../../../infrastructure/Navigation/RouteGenerator';
import { GFBrand } from '../../models/GFBrand';

class Participate extends React.Component
{
    componentDidMount()
    {
        globalComponentMountOperations.handleRouteComponentMount();
    }

    determineMarkdownContent(locale, markdownContent)
    {
        if (locale === 'en')
        {
            return markdownContent.en;
        }

        return markdownContent.de;
    }

    render()
    {
        const { locale } = this.props;

        return (
            <section className="participate-container">
                <Head
                    locale={this.props.locale}
                    ogImage={RouteGenerator.forPublicUrl('/wahrheit.jpg')}
                    ogTitle={`Glaubensfutter - ${locale === 'de' ? 'Teilnehmen' : 'Participate'}`}
                    ogDescription={GFBrand.BRAND_DESCRIPTION}
                    ogUrl={RouteGenerator.forParticipatePage(this.props.pageContext.locale).getFullUrl()}
                />
                <PageHeader
                    title="participate"
                    translateTitle={true}
                    showHr={true}
                />
                <div className="article-reader">
                    <ReactMarkdown>
                        {this.determineMarkdownContent(locale, content)}
                    </ReactMarkdown>
                </div>
                <JoinUsButtons/>
            </section>
        );
    }
}

Participate.propTypes = {
    locale: PropTypes.string,
    intl: PropTypes.object,
    pageContext: PropTypes.object,
};

export default Participate;
