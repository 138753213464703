export const content = {
    de: `
  ## Möglichkeiten der Teilnahme:
  
  ### Nimm an der Diskussion teil
Wenn Du als Christ oder religiöser Suchender daran interessiert bist, 1) die Inhalte, die wir veröffentlichen, mit uns und anderen zu diskutieren, 2) uns mit Vorschlägen oder Korrekturen für Artikelübersetzungen oder Seiteninhalte zu unterstützen oder 3) neue Ideen für Glaubensfutter vorzuschlagen, um uns besser mit der deutschsprachigen Welt zu verbinden und unsere Reichweite zu vergrößern: Du bist herzlich willkommen, unserem Slack-Arbeitsbereich beizutreten! Um Zugang dazu zu erhalten, schicke uns bitte eine E-Mail an info@glaubensfutter.de und stelle Dich vor.

Bitte beachte, dass wir nicht in der Lage sind, ein Diskussionsforum über Religion zu erstellen, zu moderieren oder einen Beitrag dazu zu leisten, da Glaubensfutter ein Teilzeitprojekt ist, für das wir leider viel zu wenig Zeit haben. Tatsächlich ist es fraglich, ob diese Foren wirklich zur Wahrheitsfindung beitragen. Deshalb beschränken wir die Teilnahme auf diejenigen, die echte Fragen und Herausforderungen zu haben scheinen oder die einen echten Wunsch zeigen, Glaubensfutter zu unterstützen. Wir behalten uns auch das Recht vor, Mitglieder zu entfernen, die nicht in diese Kategorien passen. 
  
  ### Unsere Publikationen teilen
  Wenn Dich eine Veröffentlichung, die Du hier liest, besonders bewegt oder beeindruckt, teile sie bitte in den sozialen Medien. Wir verlassen uns darauf, dass Du uns mitteilst, was Du wertvoll findest, um es weiterzugeben. Besuche unsere Social Media Seiten: [Facebook](https://www.facebook.com/glaubensfutter.de), [Instagram](https://www.instagram.com/glaubensfutter/).
  ### Feedback geben
  Wenn Du Vorschläge zu Themen hast, über die Du gerne etwas hören würdest, zu Problemen mit der Website oder mit der Leistung, kannst Du sie uns gerne anonym über unsere [anonyme Vorschlagsbox](https://forms.gle/JKjbEzZ2tLevxkES6) mitteilen.
  `,
    en: `

  ## Opportunities to Participate:
  ### Join the Discussion
  If you are interested as a Christian or religious seeker in 1) discussing the content that we post with us and others, 2) supporting us by providing suggestions or corrections for article translations or site contents, or 3) suggesting new ideas for Glaubensfutter to better connect with the German speaking world and to expand our outreach: you are very welcome to join our Slack workspace! To gain access to this, please send us an email at info@glaubensfutter.de and introduce yourself. 
  
  Please note that as Glaubensfutter is a part-time project for which we regrettably don't have tremendous amounts of time, we have no ability to create, moderate, or contribute to a forum of debate about religion. Really, it is questionable whether these forums really result in the discovery of truth anyways.  We therefore restrict participation to those who seem to have genuine questions and challenges, or who show a genuine desire to support Glaubensfutter. We also reserve the right to remove members who do not fit into these categories.
      
  ### Share our Posts
  If you find yourself particularly moved or impressed by the value of a publication you read here, please share it on social media. We rely on your being vocal about what you find valuable in order to spread the word. Feel free to visit our social media websites: [Facebook](https://www.facebook.com/glaubensfutter.de), [Instagram](https://www.instagram.com/glaubensfutter/)
  ### Give Feedback
  If you have suggestions about topics you would like to hear about, website issues or performance suggestions / issues,  please feel welcome to anonymously share them with us through our [anonymous suggestion box](https://forms.gle/JKjbEzZ2tLevxkES6).
  `,
};
