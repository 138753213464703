import withGlobalAppLayout from '../../modules/ui/containers/HOC/withGlobalAppLayout';
import Participate from '../../modules/ui/pages/Participate';

export default function index(props: any)
{
    return withGlobalAppLayout(Participate, {
        locale: props.pageContext.locale,
        hideGfLogo: false,
        ...props,
    });
}
